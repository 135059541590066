<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="inventoryModal"
    :title="$t(type) + ' ' + $t('Supply Inventory')"
    :buttons-hidden="true" autocomplete="nofill">
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <v-select autocomplete="nofill" class="select-large mt-8 w-full" name="package size" v-model="inventory.package_size" 
        v-validate="'required'" :placeholder="$t('Package Size') + '*'" label="name" :options="packagesSize"/>
        <span class="text-danger text-sm" v-show="errors.has('package size')">{{ errors.first('package size') }}</span>
      </div>
      <div class="vx-col w-1/2">
        <v-select autocomplete="nofill" class="select-large mt-8 w-full" name="type" v-model="inventory.type" 
        v-validate="'required'" :placeholder="$t('Type') + '*'" label="name" :options="types"/>
        <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
      </div>
      <div class="vx-col w-1/2">
        <vs-input
        v-validate="inventory.type && inventory.package_size && inventory.package_size.id && inventory.type.value === 'withdraw' ? `required|numeric|min_value:1|max_value:${inventoryItemsDic[inventory.package_size.id]}` : `required|numeric|min_value:1`"
        name="count" :label-placeholder="$t('Count') + '*'" v-model="inventory.count" class="mt-8 w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('count')">{{ errors.first('count') }}</span>
      </div>
      <div class="vx-col w-1/2">
        <vs-input name="reference number" :label-placeholder="$t('Reference Number')" v-model="inventory.reference_number" class="mt-8 w-full"/>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-4">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button @click="addInventory" class="active-btn btn">{{ $t('Add') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['inventoryModal', 'type', 'inventory', 'inventoryItems'], 
  data () {
    return {
      packagesSize: [],
      types: [
        {
          value: 'stock',
          name: 'Stock'
        },
        {
          value: 'withdraw',
          name: 'Withdraw'
        }
      ],
      inventoryItemsDic: {}
    }
  },
  watch: {
    inventoryModal (val) {
      if (val) {
        this.inventoryItems.forEach(element => {
          this.inventoryItemsDic[element.package_size.id] = element.count
        })
      }
    }
  },
  methods: {
    loadPackagesSize () {
      sendRequest(false, false, this, 'api/v1/package-sizes/', 'get', null, true,
        (response) => {
          this.packagesSize = response.data
        }
      )
    },
    addInventory () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const data = {
            ...this.inventory,
            package_size: this.inventory.package_size.id,
            type: this.inventory.type.value
          }
          sendRequest(false, false, this, `api/v1/warehouse/${this.$route.params.warehouseID}/supplies-inventory/transactions/`, 'post', data, true,
            () => {
              this.$emit('inventoryModal', false)
              this.$emit('loadInventory')
            }
          )
        }
      })
    },
    closeModal () {
      this.$emit('inventoryModal', false)
      this.$emit('loadInventory')
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  created () {
    this.loadPackagesSize()
  }
}
</script>