<template>
  <div id="admin-pickup-points">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Supplies Inventory')}}</h2>
      </div>
      <div v-if="localStorage.getItem('multipleWarehouses') === 'true'" class="vx-col w-full lg:w-1/2 items-end">
        <div class="flex justify-between">
          <span class="font-semibold"></span>
          <span class="font-medium text-primary cursor-pointer">
            <vs-button
              @click="openInventoryIModal('Add')"
              color="primary"
              icon-pack="feather"
              icon="icon-plus"
              size="large"
            >
            </vs-button>
          </span>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <div v-for="supplyItem in inventoryItems" :key="supplyItem.index" class="vx-col w-full lg:w-1/4 mb-base">
        <statistics-card-line v-if="supplyItem.package_size.id !== 5"
          hideChart
          :dataLoading="inventoryItemsLoading"
          icon="PackageIcon"
          :statistic="supplyItem.count"
          :statisticTitle="supplyItem.package_size.name"
        ></statistics-card-line>
      </div>
    </div>
    <shipblu-table
      multiple
      v-model="selected"
     :sst="true"
      :max-items="maximumItems"
      pagination
      :data="inventoryTransactions"
      :tableLoader="tableLoader"
    >
      
      <template slot="thead">
        <shipblu-th>{{$t('Date')}}</shipblu-th>
        <shipblu-th>{{$t('Type')}}</shipblu-th>
        <shipblu-th>{{$t('Package Size')}}</shipblu-th>
        <shipblu-th>{{$t('Count')}}</shipblu-th>
        <shipblu-th>{{$t('Reference Number')}}</shipblu-th>
        <shipblu-th>{{$t('By')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].created">
            {{  new Date(data[indextr].created).toLocaleDateString('fr-CA') }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].package">
            {{ data[indextr].type }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].package">
            {{ data[indextr].warehouse_supplies_inventory.package_size.name }}
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].count">
            {{ data[indextr].count }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].referenceNumber">
            {{ data[indextr].reference_number }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].by">
            <p v-if="data[indextr].by">{{ data[indextr].by.first_name }} {{data[indextr].by.last_name }}</p>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-inventory :type="type" :inventoryItems="inventoryItems" :inventory="inventory" :inventoryModal="inventoryModal" 
    @inventoryModal="inventoryModal = $event" @loadInventory="loadInventory"></add-inventory>
  </div>
</template>

<script>
import i18nData from '../../i18n/i18nData.js'
import AddInventory from './components/AddInventory.vue'
import { sendRequest }  from '../../http/axios/requestHelper.js'
import StatisticsCardLine from '../../components/statistics-cards/StatisticsCardLine.vue'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      selected: [],
      localStorage,
      inventory: {},
      inventoryModal: false,
      inventoryItems: [],
      inventoryItemsLoading: false,
      inventoryTransactions: [],
      deleteData: {},
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      offset: 0,
      totalRows: 0,
      tableLoader: false,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      type: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadInventoryTransaction()
    },
    '$route.params.warehouseID' () {
      this.loadInventory()
    }
  },
  methods: {
    loadInventory () {
      this.inventoryItemsLoading = true
      sendRequest(true, false, this, `api/v1/warehouse/${this.$route.params.warehouseID}/supplies-inventory/`, 'get', null, true,
        (response) => {
          this.inventoryItems = []
          response.data.results.forEach(element => {
            if (element.package_size.id !== 5) {
              this.inventoryItems.push(element)
            }
          })
          this.inventoryItems.reverse()
          this.loadInventoryTransaction()
          this.inventoryItemsLoading = false
        }
      )
    },
    loadInventoryTransaction () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/warehouse/${this.$route.params.warehouseID}/supplies-inventory/transactions/?offset=${this.offset}&limit=${this.maximumItems}`, 'get', null, true,
        (response) => {
          this.inventoryTransactions = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
        }
      )
    },
    openInventoryIModal (type, data) {
      this.type = type
      this.inventoryModal = true
      if (type === 'Add') {
        this.inventory = {
          name: '',
          count: '',
          package_size: ''
        }
      } else {
        this.inventory = data
      }
    },
    deleteInventoryItem (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/warehouse/${this.$route.params.warehouseID}/supplies-inventory/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Inventory item'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadInventory()
        }
      )
    }
  },
  components: {
    AddInventory,
    StatisticsCardLine,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadInventory()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>

<style lang="scss">
.con-pagination-table{
  display: none;
}
</style>